<template>
  <div id="app">
    <div v-if="openType === 1 && statusCode === 200">
      <TheGlobal
        :global="global"
        :extra="extra"
        :sns="sns"
        :organization="organization"
        :information="information"
        :rss="rss"
        :count="count.global"
      />
      <div class="iv2-side position-fixed">
        <TheExtra :extra="extra" />
        <TheExtraSns :sns="sns" :rss="rss" />
      </div>
      <transition mode="out-in" :name="pageAnimation" appear>
        <router-view
          :animation="pageAnimation"
          :organization="organization"
          :rss="rss"
        />
      </transition>

      <TheBanner :banner="banner" />

      <!-- <TheFooter
        :footer="footer"
        :sns="sns"
        :organization="organization"
        :information="information"
        :rss="rss"
        :translation="translation"
        :count="count.footer"
      /> -->

      <!-- customize -->
      <!-- <CustomizeStore /> -->

      <div class="iv2-content-footer">
        <TheContentFooter
          v-if="theme.is_use_content_footer === 1"
          :theme="theme"
        />
      </div>

      <CustomizeFooter
        :footer="footer"
        :sns="sns"
        :organization="organization"
        :information="information"
        :rss="rss"
      />

      <!-- タブレット、スマホのみ表示 -->
      <TheBottom :bottom="bottom" />

      <BaseColor v-if="colorDisplay" />
    </div>

    <!-- サイト非公開 -->
    <div v-else-if="openType === 0 && statusCode === 200">
      <Closed />
    </div>

    <!-- 500エラー -->
    <div v-else-if="statusCode === 500">
      <Error500 />
    </div>

    <!-- 503エラー -->
    <div v-else-if="statusCode === 503">
      <Error503 />
    </div>

    <!-- プレビュー -->
    <div v-if="preview">
      <BasePreview />
    </div>

    <!-- モーダル用オーバレイ -->
    <div class="iv2-modal-overlay hide" onclick="downModal()"></div>
  </div>
</template>

<script>
import Error500 from "./components/systems/Error500.vue";
import Error503 from "./components/systems/Error503.vue";
import Closed from "./components/systems/Closed.vue";
import TheGlobal from "./components/global/TheGlobal.vue";
import TheExtra from "./components/global/TheExtra.vue";
import TheExtraSns from "./components/global/TheExtraSns.vue";
import TheBanner from "./components/global/TheBanner.vue";
import TheContentFooter from "./components/global/TheContentFooter.vue";
// import TheFooter from "./components/global/TheFooter.vue";
import TheBottom from "./components/global/TheBottom.vue";
import BasePreview from "@/components/items/BasePreview.vue";
import InitialSetting from "@/mixins/InitialSetting";
import AddRouter from "@/mixins/AddRouter";
import BaseColor from "@/components/items/BaseColor.vue";

// customize
import CustomizeFooter from "./components/unique/CustomizeFooter.vue";

export default {
  components: {
    Error500,
    Error503,
    Closed,
    TheGlobal,
    TheExtra,
    TheExtraSns,
    TheBanner,
    TheContentFooter,
    // TheFooter,
    TheBottom,
    BasePreview,
    BaseColor,

    // customize
    CustomizeFooter,
  },

  mixins: [InitialSetting, AddRouter],

  data() {
    return {
      pageAnimation: "fade",
      colorDisplay: false,
      userAgent: "",
    };
  },

  created() {
    // QRコードからのアクセス
    const siteId = document.head.querySelector("[name=sid][content]").content;
    const id = siteId;
    const key = "qrcode";
    if (process.env.NODE_ENV === "production") {
      this.environment = "production";
    } else {
      this.environment = "dev";
    }
    window.ivGetParams(key)
      ? window.ivSendActivities(
          id,
          {
            user_agent: navigator.userAgent.toLowerCase(),
            provide_name: key,
            provide_id: window.ivGetParams(key),
          },
          this.environment
        )
      : "";

    // safariのみmp4ファイルをキャッシュから取り除く
    this.userAgent = navigator.userAgent;
    setInterval(() => {
      if (
        this.userAgent.indexOf("Safari") != -1 &&
        this.userAgent.indexOf("Chrome") == -1
      ) {
        navigator.serviceWorker.controller.postMessage("updateCache");
        window.location.reload(true);
      }
    }, 60 * 60 * 1000);

    // カラーチェンジャーの表示判定
    const display = document.head.querySelector("[name=color_changer]");
    if (display && display.content == "true") {
      this.colorDisplay = true;
    }
  },
};
</script>

<style lang="scss">
@media (min-width: 1200px) {
  .iv2-content-footer {
    margin-left: 120px;
  }
}
</style>
