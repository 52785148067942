<template>
  <!-- Footer -->
  <footer class="iv2-footer theme__footer-bg">
    <div class="iv2-footer__container theme__footer-text">
      <div class="iv2-footer__logo">
        <router-link :to="'/' + query">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_footer +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_footer"
              class="iv2-footer__logo-img"
              :alt="organization.name + $store.state.publicLang.general.alt"
            />
          </picture>
        </router-link>
      </div>
      <div class="row">
        <div class="col-xl-4 text-left">
          <p class="size-14 m-0">{{ organization.name }}</p>
          <div>
            <span
              v-if="organization.zip_code !== null"
              class="size-14 m-0 mr-2"
            >
              {{ "〒" + organization.zip_code }}
            </span>
            <span
              v-if="organization.prefecture_name !== null"
              class="size-14 m-0"
              >{{ organization.prefecture_name }}</span
            >
            <span v-if="organization.city_name !== null" class="size-14 m-0">{{
              organization.city_name
            }}</span>
            <span v-if="organization.town_name !== null" class="size-14 m-0">{{
              organization.town_name
            }}</span>
            <span
              v-if="organization.building_name !== null"
              class="size-14 m-0"
              >{{ organization.building_name }}</span
            >
          </div>
          <div class="d-flex">
            <p v-if="organization.tel_number !== null" class="size-14 m-0 mr-3">
              <span class="mr-1">{{ $store.state.themeLang.general.tel }}</span>
              <span>{{ organization.tel_number }}</span>
            </p>
            <p v-if="organization.fax_number !== null" class="size-14 m-0">
              <span class="mr-1">{{ $store.state.themeLang.general.fax }}</span>
              <span>{{ organization.fax_number }}</span>
            </p>
          </div>

          <p class="size-14">{{ information.meta_description }}</p>
        </div>
        <div class="iv2-footer__nav col-xl-4 text-left">
          <p>{{ $store.state.themeLang.general.menu }}</p>
          <div class="d-flex">
            <ul v-if="footer.length > 0" class="iv2-footer__nav-list">
              <li
                v-for="nav in footer.slice(0, 6)"
                :key="nav.id"
                class="iv2-footer__nav-item"
              >
                <router-link
                  v-if="nav.link_type == 'internal'"
                  :to="nav.link_href + query"
                  class="iv2-footer__nav-link theme__footer-link"
                  >{{ nav.item_name }}</router-link
                >
                <a
                  v-else-if="nav.link_type == 'external'"
                  :href="nav.link_href"
                  :target="nav.target_type"
                  rel="noopener"
                  class="iv2-footer__nav-link theme__footer-link"
                  >{{ nav.item_name }}</a
                >
                <ul
                  v-if="!nav.children.length == false"
                  class="iv2-footer__nav-list list-unstyled"
                >
                  <li
                    v-for="cNav in nav.children"
                    :key="cNav.id"
                    class="iv2-footer__nav-item"
                  >
                    <router-link
                      v-if="cNav.link_type == 'internal'"
                      :to="cNav.link_href + query"
                      class="iv2-footer__nav-link theme__footer-link"
                      >{{ cNav.item_name }}</router-link
                    >
                    <a
                      v-else-if="cNav.link_type == 'external'"
                      :href="cNav.link_href"
                      @click="activeMenu = false"
                      :target="cNav.target_type"
                      rel="noopener"
                      class="iv2-footer__nav-link theme__footer-link"
                      >{{ cNav.item_name }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
            <ul v-if="footer.length > 6" class="iv2-footer__nav-list">
              <li
                v-for="nav in footer.slice(6, 12)"
                :key="nav.id"
                class="iv2-footer__nav-item"
              >
                <router-link
                  v-if="nav.link_type == 'internal'"
                  :to="nav.link_href + query"
                  class="iv2-footer__nav-link theme__footer-link"
                  >{{ nav.item_name }}</router-link
                >
                <a
                  v-else-if="nav.link_type == 'external'"
                  :href="nav.link_href"
                  :target="nav.target_type"
                  rel="noopener"
                  class="iv2-footer__nav-link theme__footer-link"
                  >{{ nav.item_name }}</a
                >
                <ul
                  v-if="!nav.children.length == false"
                  class="iv2-footer__nav-list list-unstyled"
                >
                  <li
                    v-for="cNav in nav.children"
                    :key="cNav.id"
                    class="iv2-footer__nav-item"
                  >
                    <router-link
                      v-if="cNav.link_type == 'internal'"
                      :to="cNav.link_href + query"
                      class="iv2-footer__nav-link theme__footer-link"
                      >{{ cNav.item_name }}</router-link
                    >
                    <a
                      v-else-if="cNav.link_type == 'external'"
                      :href="cNav.link_href"
                      @click="activeMenu = false"
                      :target="cNav.target_type"
                      rel="noopener"
                      class="iv2-footer__nav-link theme__footer-link"
                      >{{ cNav.item_name }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
            <ul v-if="footer.length > 12" class="iv2-footer__nav-list">
              <li
                v-for="nav in footer.slice(12)"
                :key="nav.id"
                class="iv2-footer__nav-item"
              >
                <router-link
                  v-if="nav.link_type == 'internal'"
                  :to="nav.link_href + query"
                  class="iv2-footer__nav-link theme__footer-link"
                  >{{ nav.item_name }}</router-link
                >
                <a
                  v-else-if="nav.link_type == 'external'"
                  :href="nav.link_href"
                  :target="nav.target_type"
                  rel="noopener"
                  class="iv2-footer__nav-link theme__footer-link"
                  >{{ nav.item_name }}</a
                >
                <ul
                  v-if="!nav.children.length == false"
                  class="iv2-footer__nav-list list-unstyled"
                >
                  <li
                    v-for="cNav in nav.children"
                    :key="cNav.id"
                    class="iv2-footer__nav-item"
                  >
                    <router-link
                      v-if="cNav.link_type == 'internal'"
                      :to="cNav.link_href + query"
                      class="iv2-footer__nav-link theme__footer-link"
                      >{{ cNav.item_name }}</router-link
                    >
                    <a
                      v-else-if="cNav.link_type == 'external'"
                      :href="cNav.link_href"
                      @click="activeMenu = false"
                      :target="cNav.target_type"
                      rel="noopener"
                      class="iv2-footer__nav-link theme__footer-link"
                      >{{ cNav.item_name }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="iv2-footer__sns">
            <span
              v-if="
                sns.is_linked_facebook == 0 &&
                sns.is_linked_instagram == 0 &&
                sns.is_linked_twitter == 0 &&
                sns.is_linked_line == 0
              "
            ></span>
            <span v-else class="mr-4">{{
              $store.state.themeLang.general.sns
            }}</span>
            <ul style="display: flex; list-style: none">
              <li v-if="sns.is_linked_facebook == 1">
                <a
                  :href="sns.facebook_url"
                  class="iv2-footer__sns-link theme__footer-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i
                    class="iv2-footer__sns-icon text-center fab fa-facebook-f"
                  ></i>
                </a>
              </li>
              <li v-if="sns.is_linked_instagram == 1">
                <a
                  :href="sns.instagram_url"
                  class="iv2-footer__sns-link theme__footer-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i
                    class="iv2-footer__sns-icon text-center fab fa-instagram"
                  ></i>
                </a>
              </li>
              <li v-if="sns.is_linked_twitter == 1">
                <a
                  :href="sns.twitter_url"
                  class="iv2-footer__sns-link theme__footer-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i
                    class="iv2-footer__sns-icon text-center fab fa-twitter"
                  ></i>
                </a>
              </li>
              <li v-if="sns.is_linked_line == 1">
                <a
                  :href="sns.line_url"
                  class="iv2-footer__sns-link theme__footer-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="iv2-footer__sns-icon text-center fab fa-line"></i>
                </a>
              </li>
              <!-- <li v-if="rss.is_linked_blog == 1">
                <a :href="rss.url" class="iv2-footer__sns-link theme__footer-link" target="_blank" rel="noopener">
                  <i class="iv2-footer__sns-icon text-center fas fa-rss"></i>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 text-left d-none d-md-block">
          <div class="d-flex justify-content-between mb-4">
            <router-link
              v-for="btn in original_btn"
              :key="btn.id"
              :to="btn.url"
              class="iv2-footer__button text-center text-white"
            >
              {{ btn.name }}
              <i
                v-if="btn.icon !== ''"
                :class="btn.icon"
                class="iv2-footer__button-icon ml-2"
              ></i>
            </router-link>
          </div>
          <ul class="iv2-footer__extra-menu">
            <li v-for="links in original_extra" :key="links.id" class="d-block">
              <router-link :to="links.url" class="text-white">{{
                links.name
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <hr class="iv2-footer__hr theme__footer-hr" />
      <div
        v-if="organization.copy_right !== null"
        class="iv2-footer__bottom row"
      >
        <div class="iv2-footer__copyright col-xl-6 text-center text-xl-left">
          <small>{{ organization.copy_right }}</small>
        </div>
        <div class="col-6 d-none d-xl-block text-right">
          <small @click="scrollTop" class="iv2-footer__pagetop"
            >{{ $store.state.themeLang.general.page_top
            }}<i class="fas fa-angle-double-up ml-2"></i
          ></small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    footer: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
  },

  data() {
    return {
      query: "",

      original_btn: [
        {
          id: 1,
          name: "お問い合わせ",
          icon: "",
          url: "/contact",
        },
        {
          id: 2,
          name: "FAXご注文用紙",
          icon: "far fa-file-pdf",
          url: "/download",
        },
      ],

      original_extra: [
        {
          id: 1,
          name: "よくある質問",
          url: "/faq",
        },
        {
          id: 2,
          name: "プライバシーポリシー",
          url: "/privacypolicy",
        },
      ],
    };
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
  },

  methods: {
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-footer__container {
  width: 80%;
  margin: auto;

  .iv2-footer__logo {
    .iv2-footer__logo-img {
      max-width: 300px;
      max-height: 150px;
    }
  }

  .iv2-footer__nav-list {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-bottom: 30px;
    padding-left: 0;

    .iv2-footer__nav-item {
      font-size: 14px;
      display: block;
      width: 100%;

      .iv2-footer__nav-link {
        padding: 0;
        text-decoration: none;

        &::before {
          content: "-";
          margin-right: 5px;
        }
      }

      > .iv2-footer__nav-list {
        width: 100%;
        margin-bottom: 0;
        padding-top: 0;
        padding-left: 0;
        border-left: 0;

        .iv2-footer__nav-link {
          margin-left: 20px;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  .iv2-footer__extra-menu {
    padding: 0;
  }

  .iv2-footer__button {
    border: 1px solid;
    padding: 10px 10px;
    font-size: 14px;
    width: 48%;
    text-decoration: none;
  }

  .iv2-footer__sns {
    display: flex;

    .iv2-footer__sns-link {
      margin-right: 15px;

      .iv2-footer__sns-icon {
        width: 15px;
      }
    }
  }

  .iv2-footer__hr {
    padding: 0;
  }

  .iv2-footer__bottom {
    .iv2-footer__pagetop {
      cursor: pointer;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-footer {
    padding: 50px 0;
  }

  .iv2-footer__container {
    width: 85%;
    .iv2-footer__logo {
      margin-bottom: 20px;
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-footer {
    padding: 20px 0;
  }

  .iv2-footer__container {
    .iv2-footer__logo {
      margin: 25px auto;
      text-align: center;
    }

    .iv2-footer__hr {
      margin: 10px 0;
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-footer__container {
    .iv2-footer__logo {
      .iv2-footer__logo-img {
        max-width: 50%;
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-footer {
    margin-bottom: 60px;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }

  .iv2-footer__container {
    padding-bottom: 20px;

    .iv2-footer__logo {
      .iv2-footer__logo-img {
        max-width: 80%;
      }
    }

    .iv2-footer__nav {
      display: none;
    }
  }
}
</style>
