<template>
  <header class="iv2-global position-fixed">
    <div class="iv2-global__items theme__global-bg position-relative">
      <h1 class="iv2-global__logo">
        <router-link
          v-if="information.sp_logo_image_header"
          :to="'/' + lang + query"
          class="iv2-global__link"
        >
          <picture class="d-none d-md-inline">
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
          <picture class="d-md-none">
            <source
              type="image/webp"
              :srcset="
                information.sp_logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.sp_logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
        <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
      </h1>
      <div class="iv2-global__menu text-center m-auto">
        <nav class="iv2-global__sub-menu d-none d-md-flex d-xl-none">
          <div
            v-for="subnav in extra"
            :key="subnav.id"
            class="iv2-global__sub-menu-item text-center d-inline-block"
          >
            <div v-if="subnav.link_type == 'internal'">
              <router-link
                :to="subnav.link_href + lang + query"
                :target="subnav.target_type"
                class="iv2-global__sub-menu-link theme__global-sns-link text-black"
              >
                <i
                  class="iv2-global__sub-menu-item-icon d-block"
                  :class="subnav.item_icon"
                ></i>
                <span class="iv2-global__sub-menu-item-text d-block">{{
                  subnav.item_name
                }}</span>
              </router-link>
            </div>
            <div v-else-if="subnav.link_type == 'external'">
              <a
                :href="subnav.link_href"
                :target="subnav.target_type"
                rel="noopener"
                class="iv2-global__sub-menu-link theme__global-sns-link text-black"
              >
                <i
                  class="iv2-global__sub-menu-item-icon d-block"
                  :class="subnav.item_icon"
                ></i>
                <span class="iv2-global__sub-menu-item-text d-block">{{
                  subnav.item_name
                }}</span>
              </a>
            </div>
          </div>
        </nav>

        <div @click="touchHandler" class="iv2-global__btn d-inline-block">
          <div
            :class="{ active: activeMenu }"
            class="iv2-global__btn-icon position-relative bg-transparent p-0"
          >
            <span class="iv2-global__top-drawer theme__global-icon"></span>
            <span class="iv2-global__center-drawer theme__global-icon"></span>
            <span class="iv2-global__bottom-drawer theme__global-icon"></span>
          </div>
          <span
            class="iv2-global__menu-text theme__global-text d-none d-xl-block"
            >{{ menuText }}</span
          >
        </div>
      </div>
    </div>

    <nav
      :class="{ active: activeMenu }"
      class="iv2-nav theme__nav-bg theme__nav-text position-absolute vh-100"
    >
      <div class="iv2-nav__block">
        <h2 class="iv2-nav__title mt-0 mb-3">
          {{ $store.state.themeLang.general.menu }}
        </h2>
        <ul class="iv2-nav__list list-unstyled">
          <li
            v-for="nav in global"
            :key="nav.id"
            :class="{ active: activeParent == nav.id }"
            class="iv2-nav__item theme__nav-item"
          >
            <router-link
              v-if="nav.link_type == 'internal'"
              :to="nav.link_href + lang + query"
              :target="nav.target_type"
              @click.native="closeMenu"
              :class="{
                'iv2-nav__link-parent theme__nav-parent':
                  nav.children.length > 0,
              }"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</router-link
            >
            <a
              v-else-if="nav.link_type == 'external'"
              :href="nav.link_href"
              @click="closeMenu"
              :target="nav.target_type"
              rel="noopener"
              :class="{
                'iv2-nav__link-parent theme__nav-parent':
                  nav.children.length > 0,
              }"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</a
            >
            <a
              v-if="nav.children.length > 0"
              @click="activeList(nav.id)"
              class="iv2-nav__acordion theme__nav-acordion d-inline-block position-relative"
              href="javascript:void(0)"
            ></a>
            <ul
              v-if="nav.children.length > 0"
              class="iv2-nav__list list-unstyled"
            >
              <li
                v-for="cNav in nav.children"
                :key="cNav.id"
                class="iv2-nav__item theme__nav-item--inner"
              >
                <router-link
                  v-if="cNav.link_type == 'internal'"
                  :to="cNav.link_href + lang + query"
                  :target="cNav.target_type"
                  @click.native="closeMenu"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</router-link
                >
                <a
                  v-else-if="cNav.link_type == 'external'"
                  :href="cNav.link_href"
                  @click="closeMenu"
                  :target="cNav.target_type"
                  rel="noopener"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="iv2-nav__footer">
        <div class="row">
          <div class="col-12 col-sm-6">
            <small
              v-if="organization.copy_right"
              class="iv2-nav__copyright d-inline-block"
            >
              {{ organization.copy_right }}
            </small>
          </div>
          <div class="col-12 col-sm-6 text-right">
            <!-- SP SNS -->
            <div class="iv2-nav__mobile-sns d-block d-xl-none m-auto">
              <ul class="iv2-nav__mobile-sns-list mb-0 list-unstyled">
                <li
                  v-if="sns.is_linked_facebook == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.facebook_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_instagram == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.instagram_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_twitter == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.twitter_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li
                  v-if="sns.is_linked_line == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="sns.line_url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fab fa-line"></i>
                  </a>
                </li>
                <!-- <li
                  v-if="rss.is_linked_blog == 1"
                  class="iv2-nav__mobile-sns-item"
                >
                  <a
                    :href="rss.url"
                    class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                    target="_blank"
                    rel="noopener"
                  >
                    <i class="fas fa-rss"></i>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- フリーページ項目に対応した動的height値指定 -->
    <div is="style">
      @media (min-width: 1200px) {
      {{ setHeight() }}
      }
    </div>
    <!-- フリーページ項目に対応した動的height値指定 -->
  </header>
</template>

<script>
export default {
  props: {
    global: Array,
    extra: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    count: Number,
  },

  data() {
    return {
      menuText: "",
      activeMenu: false,
      activeParent: false,
      query: "",
      lang: "",
      environment: "",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.activeMenu = false;
        this.activeParent = false;
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuText = "MENU";
      }
    },
  },

  created() {
    this.menuText = "MENU";
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    activeList(key) {
      if (this.activeParent === key) {
        this.activeParent = null;
      } else {
        this.activeParent = key;
      }
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
      }

      if (this.activeMenu == true) {
        this.menuText = "CLOSE";
      } else {
        this.menuText = "MENU";
      }
    },

    closeMenu() {
      this.activeMenu = false;
      this.activeParent = false;
      this.menuText = "MENU";
      const el = document.body;
      el.classList.remove("noscroll");
    },

    /** フリーページにより大きく可変するナビゲーションに対応した高さを指定 */
    setHeight() {
      let navHeight = this.count * 30;
      if (navHeight > 350) {
        return (
          ".iv2-global .iv2-nav .iv2-nav__block>.iv2-nav__list {max-height: " +
          navHeight +
          "px;}"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  .iv2-global__items {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .iv2-global__logo {
      margin: auto;
    }

    .iv2-global__menu {
      top: 50%;
      left: 0;
      right: 0;

      .iv2-global__btn {
        cursor: pointer;
        .iv2-global__btn-icon {
          width: 20px;
          height: 20px;
          margin: 5px auto;

          .iv2-global__top-drawer,
          .iv2-global__center-drawer,
          .iv2-global__bottom-drawer {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            margin: auto;
            right: 0;
            left: 0;
            transition: 0.3s;
          }

          .iv2-global__top-drawer {
            top: 2px;
          }

          .iv2-global__center-drawer {
            top: 0;
            bottom: 0;
            visibility: visible;
            opacity: 1;
          }

          .iv2-global__bottom-drawer {
            bottom: 2px;
          }

          &.active {
            .iv2-global__top-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(-45deg);
            }

            .iv2-global__center-drawer {
              visibility: hidden;
              opacity: 0;
            }

            .iv2-global__bottom-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(45deg);
            }
          }

          &:focus {
            outline: 0;
          }
        }
      }

      .iv2-global__menu-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }

  .iv2-nav {
    top: 0;
    left: 120px;
    width: 100vw;
    visibility: hidden;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;

    &.active {
      visibility: visible;
      display: block;
      animation: tooltipShow 0.3s linear 0s;
    }

    .iv2-nav__block {
      .iv2-nav__title {
        font-size: 1rem;
      }

      > .iv2-nav__list {
        margin-top: 10px;
        margin-right: 200px;
        display: inline-block;
        vertical-align: top;

        .iv2-nav__item {
          font-size: 1.2rem;

          .iv2-nav__link {
            &:hover {
              text-decoration: none;
            }
          }

          > .iv2-nav__list {
            padding-left: 20px;

            .iv2-nav__item {
              font-size: 1.1rem;
              width: 100%;

              .iv2-nav__link {
                &::before {
                  content: "";
                  display: inline-block;
                  margin-right: 10px;
                  width: 10px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }

    .iv2-nav__footer {
      padding: 1.5rem;
      margin-left: 0;
      margin-right: 0;
    }

    .iv2-nav__copyright {
      bottom: 100px;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
    }
  }
  .iv2-global {
    .iv2-global__items {
      padding: 15px;

      .iv2-global__menu {
        position: absolute;
      }

      .iv2-global__items {
        .iv2-global__menu {
          .iv2-global__btn {
            .iv2-global__btn-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .iv2-nav {
      padding: 80px 0 80px 100px;
      .iv2-nav__block {
        > .iv2-nav__list {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          max-height: 350px;
          align-content: flex-start;

          .iv2-nav__item {
            font-size: 1.1em;
            padding-right: 15px;
            line-height: 2.5;
            width: 33.3%;
          }
        }
      }

      .iv2-nav__footer {
        padding: 0;
        margin-top: 50px;
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
    }
  }
  .iv2-global {
    .iv2-global__items {
      padding: 10px;
      .iv2-global__logo {
        display: inline-block;
        height: 100%;
        padding-left: 5px;
        margin-bottom: auto;
        position: relative;

        .iv2-global__link {
          margin-bottom: 0;
          height: 100%;
          display: inline-block;

          .iv2-global__logo-img {
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .iv2-global__menu {
        height: 100%;
        float: right;
        display: flex;
        align-items: center;

        .iv2-global__btn {
          line-height: 1;
        }

        .iv2-global__menu-text {
          margin-top: 5px;
        }
      }
    }
    .iv2-nav {
      left: 0;

      .iv2-nav__sub-menu-item {
        font-size: 1.75em;
        border-right: 1px solid;

        &:last-child {
          border-right: none;
        }
      }
      .iv2-nav__sub-menu-item-text {
        font-size: 0.5em;
        line-height: 1.3;
      }
      .iv2-nav__block {
        padding: 30px 20px 20px;

        .iv2-nav__list {
          display: block;
          margin: auto;
          float: none;

          .iv2-nav__item {
            position: relative;
            display: block;
            font-size: 1em;

            .iv2-nav__link {
              display: block;
              padding: 15px 0;
            }

            .iv2-nav__link-parent {
              width: calc(100% - 62px);
            }

            > .iv2-nav__list {
              padding-left: 0;
              visibility: hidden;
              opacity: 0;
              height: 0;

              &.active {
                visibility: visible;
                opacity: 1;
                height: auto;
                transition: 0.5s;
              }
            }

            .iv2-nav__acordion {
              width: 62px;
              height: 62px;
              float: right;
              transform: translateY(-100%);
              &::before {
                content: "";
                width: 25px;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
              }
              &::after {
                content: "";
                height: 25px;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
                transition: 0.2s;
                visibility: visible;
                opacity: 1;
              }
            }

            &.active {
              > .iv2-nav__list {
                visibility: visible;
                opacity: 1;
                height: auto;
                > .iv2-nav__item {
                  font-size: 1em;
                }
              }
              .iv2-nav__acordion {
                &::after {
                  visibility: hidden;
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      .iv2-nav__copyright {
        position: initial !important;
      }

      .iv2-footer {
        margin-left: 0;
      }

      .iv2-nav__mobile-sns-item {
        font-size: 1.5em;
        display: inline-block;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        width: 40%;
      }
      .iv2-global__menu {
        .iv2-global__btn {
          width: 75px;
        }

        .iv2-global__sub-menu {
          align-items: center;
          .iv2-global__sub-menu-item {
            font-size: 1.5rem;
            width: 80px;
            padding: 0 10px;

            .iv2-global__sub-menu-link {
              &:hover {
                text-decoration: none;
              }
            }

            .iv2-global__sub-menu-item-text {
              font-size: 10px;
              font-weight: 400;
              line-height: 1.3;
              margin-top: 2px;
            }
          }
        }
      }
    }

    .iv2-nav {
      padding: 75px 0;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        width: 50%;
      }
      .iv2-global__menu {
        .iv2-global__btn {
          width: 60px;
        }
      }
    }

    .iv2-nav {
      padding-top: 60px;
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));
    }
  }
}
</style>
