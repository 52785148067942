import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Paginate from "vuejs-paginate";
import VueCookies from "vue-cookies";
import VueTooltip from "v-tooltip";
import { RepositoryFactory } from "./repositories/repositoryFactory";
import "./registerServiceWorker";

/* cssファイル読み込み */
import "./assets/css/iv2-theme-a-component.css";

Vue.config.productionTip = false;

Vue.prototype.$repository = RepositoryFactory;

Vue.use(VueCookies);
Vue.use(VueTooltip);
Vue.component("paginate", Paginate);

/* service workerの登録 */
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then(() => {
      // console.log('登録に成功しました。 Scope は ' + reg.scope);
    })
    .catch(() => {
      // console.log('登録に失敗しました。' + error);
    });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
