var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"iv2-hero"},[(_vm.items.main_visual_type == 'slider')?_c('div',{staticClass:"iv2-hero-slider position-relative overflow-hidden",class:_vm.displayType},_vm._l((_vm.items.main_visuals),function(media){return _c('div',{key:media.id,staticClass:"iv2-hero-content"},[_c('transition',{attrs:{"name":"slider-fade","appear":""}},[(_vm.currentSlide == media.sort_key)?_c('div',{staticClass:"iv2-hero-inner"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":media.file_url + _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-image",attrs:{"src":media.file_url,"alt":_vm.organization.name + _vm.$store.state.publicLang.general.hero_alt}})]),_c('div',{staticClass:"iv2-hero-cover position-absolute"},[(media.use_link === 1 && media.is_external === 0)?_c('a',{staticClass:"iv2-hero-link d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_self"}}):(media.use_link === 1 && media.is_external === 1)?_c('a',{staticClass:"iv2-hero-link d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_blank"}}):_vm._e()]),_c('div',{staticClass:"iv2-hero-catchphrase position-absolute text-center"},[(media.use_link === 1 && media.is_external === 0)?_c('a',{staticClass:"iv2-hero-link position-absolute d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_self"}}):_vm._e(),(media.use_link === 1 && media.is_external === 1)?_c('a',{staticClass:"iv2-hero-link position-absolute d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_blank"}}):_vm._e(),_c('div',{staticClass:"row"},[(
                    media.catchphrase_type == 'img' &&
                    media.catchphrase_image_url
                  )?_c('picture',{staticClass:"iv2-hero-catchphrase__picture text-center m-auto"},[_c('source',{attrs:{"type":"image/webp","srcset":media.catchphrase_image_url +
                      _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-catchphrase__img",attrs:{"src":media.catchphrase_image_url,"alt":_vm.organization.name +
                      _vm.$store.state.publicLang.general.catchphrase_alt}})]):_vm._e(),_c('div',{staticClass:"iv2-hero-catchphrase__text col-md-12 mx-auto text-center"},[(
                      media.catchphrase_type == 'text' &&
                      media.catchphrase_text
                    )?_c('h2',{staticClass:"iv2-hero__main-title text-pre"},[_vm._v(" "+_vm._s(media.catchphrase_text)+" ")]):_vm._e(),(media.summary_text)?_c('h3',{staticClass:"iv2-hero__sub-title text-pre"},[_vm._v(" "+_vm._s(media.summary_text)+" ")]):_vm._e()])])])]):_vm._e()])],1)}),0):(_vm.items.main_visual_type == 'movie')?_c('div',{staticClass:"iv2-hero-video overflow-hidden position-relative",class:_vm.displayType},[_c('video',{staticClass:"iv2-hero-content fit-img",attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.file_url,"type":"video/mp4"}})]),_c('div',{staticClass:"iv2-hero-cover position-absolute"}),_c('div',{staticClass:"iv2-hero-catchphrase position-absolute"},[_c('div',{staticClass:"row"},[(
              _vm.data.catchphrase_type == 'img' && _vm.data.catchphrase_image_url
            )?_c('picture',{staticClass:"iv2-hero-catchphrase__picture text-center m-auto"},[_c('source',{attrs:{"type":"image/webp","srcset":_vm.data.catchphrase_image_url +
                _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-catchphrase__img",attrs:{"src":_vm.data.catchphrase_image_url,"alt":_vm.organization.name +
                _vm.$store.state.publicLang.general.catchphrase_alt}})]):_vm._e(),_c('div',{staticClass:"iv2-hero-catchphrase__text col-md-12 mx-auto text-center"},[(_vm.data.catchphrase_type == 'text' && _vm.data.catchphrase_text)?_c('h2',{staticClass:"iv2-hero__main-title text-pre"},[_vm._v(" "+_vm._s(_vm.data.catchphrase_text)+" ")]):_vm._e(),(_vm.data.summary_text)?_c('h3',{staticClass:"iv2-hero__sub-title text-pre"},[_vm._v(" "+_vm._s(_vm.data.summary_text)+" ")]):_vm._e()])]),(_vm.data.use_button == 1)?_c('div',{staticClass:"mt-3 text-center"},[_c('span',[_c('a',{staticClass:"iv2-hero-btn theme__main-btn is-btn is-btn-ghost2 size-18",attrs:{"href":_vm.data.link_href,"target":_vm.windowSelected}},[_vm._v(_vm._s(_vm.data.button_label))])])]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }